import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { VersionResponse } from '../responses/version-response';
import info from './../../info.json';
import { LoginResponse } from '../responses/account/account/login-response';
import { InfoResponse } from '../responses/account/account/info-response';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  authenticationChanged: EventEmitter<any> = new EventEmitter();
  accountDisabledEnabledChanged: EventEmitter<any> = new EventEmitter();
  private readonly ACCOUNT_HOST: string = info.ArmyPlanner.Account.ServiceUrl;
  private bearerToken: string = '';
  private accountDisabled: boolean = false;

  constructor(public http: HttpClient) { }

  async getVersion(): Promise<string> {
    const httpResponse = (await fetch(`${this.ACCOUNT_HOST}/Version`));
    const jsonResponse = await httpResponse.json();
    const response = new VersionResponse(jsonResponse.version);

    return response.version;
  }

  async disableUser(): Promise<void> {
    const httpResponse = (await fetch(`${this.ACCOUNT_HOST}/Account/Disable`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.bearerToken}`
      }
    }));

    if (!httpResponse.ok) {
      const errorBody = await httpResponse.json();
      throw new Error(errorBody.errorMessage);
    }
  }

  async enableUser(): Promise<void> {
    const httpResponse = (await fetch(`${this.ACCOUNT_HOST}/Account/Enable`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.bearerToken}`
      }
    }));

    if (!httpResponse.ok) {
      const errorBody = await httpResponse.json();
      throw new Error(errorBody.errorMessage);
    }
  }

  async logout(): Promise<void> {
    this.bearerToken = '';

    this.authenticationChanged.emit();
  }

  async getUserInformations(): Promise<InfoResponse> {
    const httpResponse = (await fetch(`${this.ACCOUNT_HOST}/Account/Info`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.bearerToken}`
      },
    }));

    if (!httpResponse.ok) {
      const errorBody = await httpResponse.json();
      throw new Error(errorBody.errorMessage);
    }

    const jsonResponse = await httpResponse.json();
    const response = new InfoResponse(jsonResponse.email, jsonResponse.username, jsonResponse.isDisabled);

    this.accountDisabled = response.isDisabled;
    this.accountDisabledEnabledChanged.emit();

    return response;
  }

  async register(username: string, email: string, password: string): Promise<void> {
    const httpResponse = (await fetch(`${this.ACCOUNT_HOST}/Account/Register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        username: username,
        password: password
      })
    }));

    if (!httpResponse.ok) {
      const errorBody = await httpResponse.json();
      throw new Error(errorBody.errorMessage);
    }
  }

  async login(account: string, password: string): Promise<void> {
    const httpResponse = (await fetch(`${this.ACCOUNT_HOST}/Account/Login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        account: account,
        password: password
      })
    }));

    if (!httpResponse.ok) {
      const errorBody = await httpResponse.json();
      throw new Error(errorBody.errorMessage);
    }

    const jsonResponse = await httpResponse.json();
    const response = new LoginResponse(jsonResponse.jwtToken);

    this.bearerToken = response.jwtToken;

    this.authenticationChanged.emit();
  }

  async getBearerToken(): Promise<string> {
    return this.bearerToken;
  }

  async isAuthenticated(): Promise<boolean> {
    return this.bearerToken !== '';
  }

  async isAccountDisabled(): Promise<boolean> {
    return this.accountDisabled;
  }
}
