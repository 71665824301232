export class InfoResponse {
    email: string;
    username: string;
    isDisabled: boolean;

    constructor(email: string, username: string, isDisabled: boolean) {
        this.email = email;
        this.username = username;
        this.isDisabled = isDisabled;
    }
}
