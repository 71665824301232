<ion-app>

  <ion-split-pane contentId="main-content">

    <ion-menu contentId="main-content" type="overlay">

      <ion-header [translucent]="true">

        <ion-toolbar color="primary">

          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>

          <ion-title>ArmyPlanner</ion-title>

        </ion-toolbar>

      </ion-header>

      <ion-content [fullscreen]="true">

        <ion-header collapse="condense">
          <ion-toolbar>
            <ion-title size="large">ArmyPlanner</ion-title>
          </ion-toolbar>
        </ion-header>

        <ion-list id="inbox-list">

          <ion-list-header *ngIf="isLoggedIn">{{ authUsername }}</ion-list-header>
          <ion-note *ngIf="isLoggedIn">{{ authEmail }}</ion-note>

          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">

            <ion-item color="primary" size="small" routerDirection="root" [routerLink]="[p.url]" lines="none"
              detail="false" routerLinkActive="selected" color="">
              <ion-icon aria-hidden="true" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>

          </ion-menu-toggle>

        </ion-list>

      </ion-content>

    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-split-pane>

</ion-app>