import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'schemes',
    pathMatch: 'full',
  },
  {
    path: 'colors',
    loadComponent: () => import('./pages/colors/color-overview/color-overview.page').then( m => m.ColorOverviewPage)
  },
  {
    path: 'schemes',
    loadComponent: () => import('./pages/schemes/schema-overview/schema-overview.page').then( m => m.SchemaOverviewPage)
  },
  {
    path: 'settings',
    loadComponent: () => import('./pages/app/settings/settings.page').then( m => m.SettingsPage)
  },
  {
    path: 'schemes/:id',
    loadComponent: () => import('./pages/schemes/schema-detail/schema-detail.page').then( m => m.SchemaDetailPage)
  },
  {
    path: 'shopping-list',
    loadComponent: () => import('./pages/shopping-list/overview-page/overview-page.page').then( m => m.OverviewPagePage)
  },
];
