import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { IonMenuButton, IonApp, IonTitle, IonHeader, IonToolbar, IonButton, IonButtons, IonSplitPane, IonMenu, IonContent, IonList, IonListHeader, IonNote, IonMenuToggle, IonItem, IonIcon, IonLabel, IonRouterOutlet } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { menuOutline, menuSharp, albumsOutline, albumsSharp, fileTrayStackedOutline, fileTrayStackedSharp, settingsOutline, settingsSharp, cartOutline, cartSharp } from 'ionicons/icons';
import { AccountService } from './services/account.service';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    CommonModule,
    IonMenuButton,
    IonTitle,
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonApp,
    IonSplitPane,
    IonMenu,
    IonContent,
    IonList,
    IonListHeader,
    IonNote,
    IonMenuToggle,
    IonItem,
    IonIcon,
    IonLabel,
    IonRouterOutlet
  ],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'Schemes', url: '/schemes', icon: 'albums' },
    { title: 'Colors', url: '/colors', icon: 'file-tray-stacked' },
    { title: 'Shopping List', url: '/shopping-list', icon: 'cart' },
    { title: 'Settings', url: '/settings', icon: 'settings' },
  ];
  public isLoginFailedToastOpen = false;
  public errorMessage: string = '';
  public authUsername: string = '';
  public authEmail: string = '';
  public isLoggedIn: boolean = false;

  constructor(public accountService: AccountService,
    private swUpdate: SwUpdate,
    private platform: Platform,
    private alertController: AlertController) {
    addIcons({ menuOutline, menuSharp, albumsOutline, albumsSharp, fileTrayStackedOutline, fileTrayStackedSharp, settingsOutline, settingsSharp, cartOutline, cartSharp });
  }

  async ngOnInit() {
    this.checkMacOSTheme();
    await this.loadingLogin();
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      setInterval(() => {
        this.swUpdate.checkForUpdate();
      }, 21600);
    });
  }

  public checkMacOSTheme() {
    this.platform.ready().then(() => {
      const userAgent = navigator.userAgent;

      if (userAgent.includes('Macintosh') && userAgent.includes('Safari')) {
        // use ionic ios theme
      }
    });
  }

  public async loadingLogin() {
    this.accountService.authenticationChanged.subscribe(async () => {
      this.isLoggedIn = await this.accountService.isAuthenticated();
      if (this.isLoggedIn) {
        await this.loadUserInfo();
      }
    });


    this.isLoginFailedToastOpen = false;

    let account = localStorage.getItem('loginAccount');
    let password = localStorage.getItem('loginPassword');

    if (account == null || password == null) {
      return;
    }

    try {
      await this.accountService.login(account,
        password);
      await this.loadUserInfo();
    }
    catch (err: unknown) {
      if (err instanceof Error) {
        this.isLoginFailedToastOpen = true;
        this.errorMessage = err.message;
      }
    }
  }

  public async loadUserInfo() {
    let userInfo = await this.accountService.getUserInformations();
    this.authEmail = userInfo.email;
    this.authUsername = userInfo.username;
  }
}